
.scolumn {
  float: left;
  width: 50%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-left: 50px solid white ;
}

.column {
  float: left;
  width: 50%;
  border-left: 20px solid white ;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


